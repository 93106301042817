
import { ExternalLink } from 'lucide-react';
import { useIsMobile } from '@/hooks/use-mobile';
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";

const PromotionalBanner = ({ className = "" }: { className?: string }) => {
  const isMobile = useIsMobile();

  return (
    <Card className={`relative border-2 border-blue-500 shadow-md overflow-hidden ${className}`}>
      <div className="absolute top-1 left-2">
        <span className="text-xs text-slate-500 font-medium">Mainos</span>
      </div>
      <div className="flex flex-col p-4 pt-6">
        <div className="flex justify-center mb-3">
          <img 
            src="/lovable-uploads/3543498f-7729-44f7-a7f0-c5461151e9ba.png" 
            alt="Vattenfall" 
            className="h-12 w-auto"
            loading="lazy" 
          />
        </div>
        
        <h4 className="text-center font-bold text-blue-800 mb-2">Perusmaksut 0€/6kk</h4>
        
        <p className={`text-sm text-slate-700 mb-4 text-center`}>
          Tarjous: Vattenfall Tuntisähkö Mix perusmaksut nyt 0€ ensimmäisen 6kk ajaksi!
        </p>
        
        <div className="mt-auto">
          <a 
            href="https://go.adt267.com/t/t?a=1884560810&as=1498692683&t=2&tk=1&epi=testi-1" 
            target="_blank" 
            rel="noopener noreferrer"
            className="text-sm bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded flex items-center gap-1.5 transition-colors whitespace-nowrap justify-center shadow-lg w-4/5 mx-auto font-medium"
          >
            Katso lisää
            <ExternalLink className="h-3.5 w-3.5" />
          </a>
        </div>
      </div>
    </Card>
  );
};

export default PromotionalBanner;
